<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1 class="custom-header-title">
                  Editing {{ serviceForm.stringID }}: Payment Details - {{ serviceForm.customerName }}
                </h1>
                <p class="log_info">
                  Created by {{ serviceForm.createdBy ? serviceForm.createdBy.name : '' }} on {{ dateFormatWithTime(serviceForm.createdAt) }}<br>Last updated on {{ dateFormatWithTime(serviceForm.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="serviceFormEditForm"
        #default="{invalid}"
      >

        <b-card
          ref="payment_details"
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Payment Details
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Payment Status"
                vid="paymentStatus"
                rules="required"
              >
                <b-form-group
                  label="Payment Status*"
                  label-for="h-bookings-paymentStatus"
                  label-cols-md="4"
                  :state="(errors.length > 0 || paymentStatusValidation) ? false : null"
                >
                  <v-select
                    id="h-bookings-paymentStatus"
                    v-model="paymentStatus"
                    label="title"
                    placeholder="Select from list"
                    :options="paymentStatusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="paymentStatusValidation == true ? paymentStatusValidation = false : null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentStatusValidation"
                    class="text-danger"
                  >
                    {{ paymentStatusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" />
            <b-col cols="12">
              <b-form-group
                label="Payment Remarks"
                label-for="h-booking-paymentRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Payment Remarks"
                  vid="paymentRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="event-paymentRemarks"
                    v-model="paymentRemarks"
                    placeholder="Payment Remarks"
                    rows="3"
                    name="paymentRemarks"
                    @input="paymentRemarksValidation == true ? paymentRemarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="paymentRemarksValidation"
                    class="text-danger"
                  >
                    {{ paymentRemarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-service-forms-show', params: { id: $route.params.id } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormTextarea, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    vSelect,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      savedData: false,
      serviceForm: {},
      paymentRemarks: '',
      paymentRemarksError: 'Valid value is required',
      paymentRemarksValidation: false,
      paymentStatus: 'N/A',
      paymentStatusError: '',
      paymentStatusValidation: false,
      paymentStatusOptions: [
        { title: 'Paid', code: 'Paid' },
        { title: 'Partially Paid', code: 'Partially Paid' },
        { title: 'Unpaid', code: 'Unpaid' },
        { title: 'FSP', code: 'FSP' },
        { title: 'N/A', code: 'N/A' },
      ],

      // validation rules
      required,
    }
  },

  // updated() {
  //   const { section } = this.$route.query
  //   if (section) {
  //     const element = this.$refs[section]
  //     if (element) {
  //       const top = element.offsetTop - 135
  //       window.scrollTo({
  //         top,
  //         behavior: 'smooth',
  //       })
  //     }
  //   }
  // },

  created() {
    this.$http.get(`operation/service-forms/${this.$route.params.id}`, { params: { department: true } })
      .then(response => {
        this.serviceForm = response.data.data
        this.paymentRemarks = response.data.data.paymentRemarks || ''
        this.paymentStatus = response.data.data.paymentStatus || 'N/A'

        document.title = `Editing ${response.data.data.stringID} - ${response.data.data.customerName} | ${process.env.VUE_APP_APP_NAME || 'Nirvana Memorial Garden'}`
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    cancelEdit() {
      this.$swal({
        title: 'Are you sure?',
        html: 'Selecting Cancel will <strong>undo any changes</strong> you have made. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Cancel!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'operation-service-forms-show', params: { id: this.$route.params.id } })
          }
        })
    },
    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.service_requests.scrollHeight)
    //   })
    // },
    submitForm() {
      this.$refs.serviceFormEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('paymentRemarks', this.paymentRemarks)
          formData.append('paymentStatus', this.paymentStatus)

          this.$http.patch(`operation/service-forms/payments/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Service Form Updated',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Entry',
                  allowOutsideClick: false,
                  confirmButtonText: 'Return to Service Form',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    this.savedData = true
                    if (result.value) {
                      this.$router.push({ name: 'operation-service-forms-index', params: { type: 'all-service-forms' } })
                    } else {
                      this.$router.push({ name: 'operation-service-forms-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'paymentRemarks') {
                    this.paymentRemarksError = validationError.msg
                    this.paymentRemarksValidation = true
                  } else if (validationError.param === 'paymentStatus') {
                    this.paymentStatusError = validationError.msg
                    this.paymentStatusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
</style>
